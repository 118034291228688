section.tq-page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: white;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  overflow-y: scroll;
  color: #000105;
  opacity: 1;
  transition: all 0.35s ease-in;
  .brand{
		display: flex;
		z-index: 999;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		padding: 30px 50px;
		svg.logo-bg{
			position: absolute;
			z-index: -1;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
		}
		svg.logo {
			height: 50px;
			.st0{fill:#747272;}
      .st1{fill:#C3161C;}
      .st2{fill:#EC1C24;}
      .st3{fill:#9D0B0F;}
			
		}
		@media screen and (max-width: 576px) {
			padding: 15px 30px;
		
		}
	}
  .cctqbox{
    padding: 60px 15px 30dvh;
    width: 400px;
    max-width: calc(100dvw - 30px);
    height: 100dvh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  h3{
    font-size: 24px;
    font-weight: bold;
    + p{
      margin-top: 15px;
    }
  }
  p{
    font-size: 18px;
  }
  button, a{
    margin-top: 30px;
    font-weight: bold;
  }
}