@import url('https://fonts.googleapis.com/css2?family=Overpass:wght@300;700;900&display=swap');
@import 'reset';

$black: #000105;
$background: #fff;
$light: #fff;
$text: #000;
$red: #ec1c24;
$white: white;

%text-gradient {
  background: -webkit-linear-gradient($red, #96001d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.25);
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-thumb {
  background: $red;
  width: 6px;
  height: 6px;
  border-radius: 3px;
}

html,
body {
  overflow-x: hidden;
  scroll-behavior: smooth;
}
body {
  background-color: $background;
  color: $text;
  font-family: 'Overpass', sans-serif;
  font-weight: 300;
  &.payment-page-active {
    overflow: hidden;
  }
}

.container {
  width: 1140px;
  padding: 0 15px;
  max-width: 100%;
  margin: auto;
  @media screen and (max-width: 576px) {
    padding: 0 8px;
  }
}

h1 {
  font-size: 50px;
  font-weight: bold;
  + p {
    font-size: 26px;
    margin: 0;
    margin-top: 30px;
  }
  @media screen and (max-width: 576px) {
    font-size: 36px;
    line-height: 1.1;
    + p {
      font-size: 18px;
    }
  }
}
h2 {
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
  + p {
    font-size: 14px;
    margin-top: 8px;
    line-height: 24px;
    opacity: 0.7;
  }
  @media screen and (max-width: 576px) {
    font-size: 18px;
    + p {
      line-height: 1.2;
    }
  }
}

p {
  a {
    color: $red;
  }
  + p {
    margin-top: 15px;
  }
}

.arrow {
  width: 2px;
  height: 2px;
  border: solid white;
  border-width: 0 2px 2px 0;
  display: block;
  padding: 2px;
  margin-left: 5px;
  margin-bottom: 5px;
  &.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
}

.text-gr {
  @extend %text-gradient;
}

.btn {
  display: flex;
  cursor: pointer;
  padding: 10px 15px 8px;
  color: $white;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  border: 2px solid rgba(255, 255, 255, 0.2);
  line-height: 1;
  transition: 0.5s;
  &.btn-red {
    padding: 18px 20px 15px;
    background: $red;
    border: 0;
    background: -moz-linear-gradient(180deg, #9d0b0f 0%, $red 100%);
    background: -webkit-linear-gradient(180deg, #9d0b0f 0%, $red 100%);
    background: linear-gradient(to top, #9d0b0f 0%, $red 100%);
    @media screen and (max-width: 576px) {
      font-size: 24px;
      font-weight: bold;
      padding: 20px 30px 15px;
    }
  }
}

a,
button {
  cursor: pointer;
}

header {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  padding: 15px;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.5);
  @media screen and (max-width: 576px) {
    padding: 10px 8px;
    justify-content: space-between;
  }
  .brand {
    display: flex;
    z-index: 999;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 7px 50px;
    @media screen and (max-width: 576px) {
      padding: 4px 30px;
    }

    .logo {
      height: 50px;
      @media screen and (max-width: 576px) {
        height: 45px;
      }
      path {
        fill: white;
      }
    }
    @media screen and (max-width: 576px) {
      padding: 4px 30px;
    }
  }
  .lang-menu {
    text-transform: capitalize;
    // background-color: rgba(0,0,0,0.4);
    position: relative;
    font-size: 16px;
    display: flex;
    cursor: pointer;
    border: 2px solid rgba(255, 255, 255, 0.2);
    line-height: 1;
    transition: 0.5s;
    color: $black;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    &:hover {
      // background: $red;
      // background: -moz-linear-gradient(left, #96001D 0%, #DF001A 100%);
      // background: -webkit-linear-gradient(left, #96001D 0%, #DF001A 100%);
      // background: linear-gradient(to right, #96001D 0%, #DF001A 100%);
      border-color: transparent;
    }
    svg {
      width: 15px;
      height: 15px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      path {
        fill: $black;
      }
      &.icon-globe {
        left: 5px;
      }
      &.icon-down {
        right: 5px;
      }
    }
    select {
      position: relative;
      z-index: 2;
      padding: 8px 25px 5px;
    }
    @media screen and (min-width: 577px) {
      &:not(:last-child) {
        margin-right: 15px;
      }
    }
    @media screen and (max-width: 576px) {
      font-size: 14px;
    }
  }
  .btn {
    font-size: 16px;
    padding: 8px 8px 5px;
    color: $black;
    // background-color: rgba(0,0,0,0.4);
    text-transform: capitalize;
    &:hover,
    &:focus {
      // background: $red;
      // background: -moz-linear-gradient(left, #96001D 0%, #DF001A 100%);
      // background: -webkit-linear-gradient(left, #96001D 0%, #DF001A 100%);
      // background: linear-gradient(to right, #96001D 0%, #DF001A 100%);
      border-color: transparent;
    }
    @media screen and (max-width: 576px) {
      font-size: 14px;
    }
  }
}

.sec1 {
  min-height: 100vh;
  display: flex;
  position: relative;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 15px;
  overflow-x: hidden;
  padding-top: 150px;
  @media screen and (max-width: 576px) {
    min-height: 80vh;
    justify-content: flex-end;
  }
  h1,
  p {
    width: 800px;
    max-width: 100%;
  }
  .btn {
    margin-top: 80px;
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    z-index: -1;
    background: #fff;
    background: rgba(0, 0, 0, 0);
    background: -moz-linear-gradient(top, transparent 90%, white 100%);
    background: -webkit-linear-gradient(top, transparent 90%, white 100%);
    background: linear-gradient(to bottom, transparent 90%, white 100%);
  }
  .bg-m {
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: -2;
    @media screen and (max-width: 576px) {
      height: auto;
      width: 150vw;
      top: 0;
    }
  }
  .scroll {
    display: block;
    position: absolute;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
    //opacity: .5;
    @media screen and (max-width: 576px) {
      display: none;
    }
  }
}

.sec2 {
  padding: 0 15px;
  margin-top: 10vh;
  @media screen and (max-width: 576px) {
    margin-top: 220px;
  }
  .container {
    background-color: rgba(255, 255, 255, 0.12);
    border-radius: 15px;
    padding: 50px 30px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    @media screen and (max-width: 576px) {
      flex-direction: column;
      align-items: center;
      text-align: center;
      justify-content: flex-start;
      padding: 80px 15px 50px;
    }
  }
  img.phone {
    position: absolute;
    right: calc(50% + 80px);
    // top: -80px;
    bottom: 0;
    width: 300px;
    max-width: calc(50% - 100px);
    @media screen and (max-width: 576px) {
      width: 200px;
      max-width: 100%;
      top: 30px;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
    }
  }
  .content {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media screen and (max-width: 576px) {
      width: 100%;
    }
  }
  h1 {
    margin-bottom: 30px;
  }
  .each p {
    font-size: 14px;
    line-height: 24px;
    opacity: 0.7;
  }
  .each + .each {
    margin-top: 15px;
  }
}

.sec3 {
  position: relative;
  text-align: center;
  padding-top: 150px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  @media screen and (max-width: 576px) {
    padding: 100px 8px;
    overflow-x: hidden;
  }

  h1,
  p {
    width: 950px;
    max-width: 100%;
  }
  .movio-scene {
    margin-top: 30px;
    @media screen and (max-width: 576px) {
      width: 220px;
      max-width: 100%;
    }
  }
  .strip {
    width: 100%;
    position: absolute;
    top: 0;
    opacity: 0.4;
    z-index: -2;
    @media screen and (max-width: 576px) {
      width: auto;
      max-width: none;
      height: 80vh;
      left: 0;
    }
  }
  .features {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .each {
      width: 350px;
      padding: 15px;
      position: relative;
      margin-top: 80px;
      &::before {
        position: absolute;
        z-index: -1;
        background: -webkit-linear-gradient(rgba(255, 255, 255, 0.38), transparent 80%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 200px;
        font-weight: 900;
        left: 50%;
        bottom: -30px;
        transform: translateX(-50%);
      }
      @media screen and (max-width: 576px) {
        padding: 15px 8px;
        &::before {
          font-size: 150px;
          bottom: 0;
        }
      }
      &:nth-child(1)::before {
        content: '1';
      }
      &:nth-child(2)::before {
        content: '2';
      }
      &:nth-child(3)::before {
        content: '3';
      }
    }
  }
}

footer {
  background-color: rgba(255, 255, 255, 0.1);
  // text-align: center;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // position: relative;
  // min-height: 500px;
  // overflow-x: hidden;
  margin-top: 50px;
  background: rgba(255, 255, 255, 0.08);
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0) 150%);
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.08) 0, rgba(255, 255, 255, 0) 150%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.08) 0, rgba(255, 255, 255, 0) 150%);
  text-align: center;
  @media screen and (max-width: 576px) {
    margin-top: 0;
  }
  a {
    border-bottom: 1px dashed $red;
    display: inline;
    color: $text;
  }
  > .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  h1 {
    display: block;
    width: 100%;
    padding: 50px 0 30px;
  }
  .logo-wrap {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    @media screen and (max-width: 576px) {
      justify-content: center;
    }
    .logo {
      fill: $red;
      height: 30px;
    }
  }
  .footer-wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 15px 0 30px;
    width: 100%;
    font-size: 14px;
    .footer-left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
    }
    .footer-right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-left: 30px;
      text-align: right;
    }
    @media screen and (max-width: 576px) {
      flex-direction: column;
      .footer-left,
      .footer-right {
        text-align: center;
        width: 100%;
        align-items: center;
        margin-top: 15px;
      }
    }
    .customer-care {
      h3 {
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
      }
      .list-wrap {
        display: table;
        @media screen and (max-width: 576px) {
          margin: auto;
        }
        .each {
          display: table-row;
          transition: 0.3s;
          opacity: 0.7;
          &:hover {
            color: $red;
            background-color: $black;
            opacity: 1;
          }
          img {
            width: 15px;
          }
          .icon,
          .details {
            display: table-cell;
            text-align: left;
          }
          .icon {
            vertical-align: bottom;
            padding-top: 3px;
          }
          .details {
            padding-left: 5px;
            vertical-align: middle;
          }
        }
      }
    }
    .company-info {
      strong {
        font-weight: bold;
        font-size: 16px;
      }
    }
    .copyright {
      margin-top: 15px;
    }
  }
  .footer-bottom {
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    padding: 15px 0;
    .container {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      @media screen and (max-width: 576px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
    .credit-card-icon {
      img {
        height: 30px;
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }
  .quick-links {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    margin-bottom: 15px;
    .each {
      display: flex;
      padding: 5px 0 2px;
      margin: 0 10px;
      position: relative;
      opacity: 0.7;
      transition: 0.3s;
      &:hover {
        opacity: 1;
      }
      &:first-child {
        margin-left: 0;
      }
      &:not(:last-child)::after {
        content: '';
        display: block;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: white;
        position: absolute;
        right: -12px;
        top: calc(50% - 2px);
      }
    }
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9;
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  transition: all 0.35s ease-in;
  &.is-visible {
    visibility: visible;
    opacity: 1;
  }
  .modal-dialog {
    position: relative;
    width: 420px;
    height: auto;
    max-width: calc(100vw - 16px);
    max-height: calc(100vh - 16px);
    border-radius: 5px;
    overflow: auto;
    cursor: default;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: white;
    color: $black;
    align-items: center;
    text-align: center;
    line-height: 1.2;
    @media screen and (max-width: 576px) {
      max-width: calc(100vw - 30px);
      max-height: calc(100vh - 30px);
    }
  }
  h1 {
    font-size: 36px;
    margin-bottom: 30px;
    line-height: 1;
    + p {
      margin-top: 0;
      font-size: 14px;
    }
  }
  .close-modal {
    font-size: 20px;
    position: absolute;
    top: 15px;
    right: 15px;
    opacity: 0.5;
  }
  .modal-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    iframe {
      border: 0;
      width: 100%;
      height: 80vh;
      width: 420px;
      max-width: 100%;
      display: block;
    }
    h1 {
      margin-top: 30px;
      margin-bottom: 15px;
    }
    .disclaimer {
      margin-bottom: 15px;
      a {
        color: $red;
        border-bottom: 1px dashed $red;
        display: inline;
      }
    }
    @media screen and (max-width: 576px) {
      padding: 0;
    }
    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 30px;
      .form-group {
        text-align: left;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 20px;
        + [type='submit'] {
          margin-top: 30px;
          font-size: 18px;
          font-weight: bold;
        }
      }
      .form-label {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 5px;
        display: block;
        width: 100%;
        padding: 0 2px 0 15px;
      }
      .form-control {
        height: 40px;
        border: 1px solid rgba(0, 0, 0, 0.4);
        border-radius: 4px;
        width: 320px;
        font-size: 18px;
        font-weight: bold;
        padding: 2px 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 1;

        background-color: transparent;

        &::placeholder {
          opacity: 0.5;
        }
        &:-ms-input-placeholder {
          opacity: 0.5;
        }
        &::-ms-input-placeholder {
          opacity: 0.5;
        }
        + div[class*='error'] {
          font-size: 14px;
          color: $red;
          margin-top: 5px;
        }
        &:not(:last-child) {
          border-color: $red;
        }
      }
      div[class*='Select-module'] {
        position: relative;
        &::before {
          content: '';
          right: 15px;
          position: absolute;
          top: calc(50% - 6px);
          width: 10px;
          height: 10px;
          border: solid $black;
          border-width: 0 2px 2px 0;
          display: block;
          padding: 2px;
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
        }
        .form-control {
          padding-right: 35px;
        }
      }
    }
  }
}

.price-point {
  font-size: 14px;
  margin: 15px auto;
}

.loader {
  margin: 100px auto;
  font-size: 10px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
@-webkit-keyframes load5 {
  0%,
  100% {
    box-shadow:
      0em -2.6em 0em 0em #ffffff,
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2),
      2.5em 0em 0 0em rgba(0, 0, 0, 0.2),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2),
      0em 2.5em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2),
      -2.6em 0em 0 0em rgba(0, 0, 0, 0.5),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.7);
  }
  12.5% {
    box-shadow:
      0em -2.6em 0em 0em rgba(0, 0, 0, 0.7),
      1.8em -1.8em 0 0em #ffffff,
      2.5em 0em 0 0em rgba(0, 0, 0, 0.2),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2),
      0em 2.5em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2),
      -2.6em 0em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.5);
  }
  25% {
    box-shadow:
      0em -2.6em 0em 0em rgba(0, 0, 0, 0.5),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.7),
      2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2),
      0em 2.5em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2),
      -2.6em 0em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2);
  }
  37.5% {
    box-shadow:
      0em -2.6em 0em 0em rgba(0, 0, 0, 0.2),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.5),
      2.5em 0em 0 0em rgba(0, 0, 0, 0.7),
      1.75em 1.75em 0 0em #ffffff,
      0em 2.5em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2),
      -2.6em 0em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2);
  }
  50% {
    box-shadow:
      0em -2.6em 0em 0em rgba(0, 0, 0, 0.2),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2),
      2.5em 0em 0 0em rgba(0, 0, 0, 0.5),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.7),
      0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2),
      -2.6em 0em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2);
  }
  62.5% {
    box-shadow:
      0em -2.6em 0em 0em rgba(0, 0, 0, 0.2),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2),
      2.5em 0em 0 0em rgba(0, 0, 0, 0.2),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.5),
      0em 2.5em 0 0em rgba(0, 0, 0, 0.7),
      -1.8em 1.8em 0 0em #ffffff,
      -2.6em 0em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2);
  }
  75% {
    box-shadow:
      0em -2.6em 0em 0em rgba(0, 0, 0, 0.2),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2),
      2.5em 0em 0 0em rgba(0, 0, 0, 0.2),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2),
      0em 2.5em 0 0em rgba(0, 0, 0, 0.5),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.7),
      -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2);
  }
  87.5% {
    box-shadow:
      0em -2.6em 0em 0em rgba(0, 0, 0, 0.2),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2),
      2.5em 0em 0 0em rgba(0, 0, 0, 0.2),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2),
      0em 2.5em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.5),
      -2.6em 0em 0 0em rgba(0, 0, 0, 0.7),
      -1.8em -1.8em 0 0em #ffffff;
  }
}
@keyframes load5 {
  0%,
  100% {
    box-shadow:
      0em -2.6em 0em 0em #ffffff,
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2),
      2.5em 0em 0 0em rgba(0, 0, 0, 0.2),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2),
      0em 2.5em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2),
      -2.6em 0em 0 0em rgba(0, 0, 0, 0.5),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.7);
  }
  12.5% {
    box-shadow:
      0em -2.6em 0em 0em rgba(0, 0, 0, 0.7),
      1.8em -1.8em 0 0em #ffffff,
      2.5em 0em 0 0em rgba(0, 0, 0, 0.2),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2),
      0em 2.5em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2),
      -2.6em 0em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.5);
  }
  25% {
    box-shadow:
      0em -2.6em 0em 0em rgba(0, 0, 0, 0.5),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.7),
      2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2),
      0em 2.5em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2),
      -2.6em 0em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2);
  }
  37.5% {
    box-shadow:
      0em -2.6em 0em 0em rgba(0, 0, 0, 0.2),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.5),
      2.5em 0em 0 0em rgba(0, 0, 0, 0.7),
      1.75em 1.75em 0 0em #ffffff,
      0em 2.5em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2),
      -2.6em 0em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2);
  }
  50% {
    box-shadow:
      0em -2.6em 0em 0em rgba(0, 0, 0, 0.2),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2),
      2.5em 0em 0 0em rgba(0, 0, 0, 0.5),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.7),
      0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2),
      -2.6em 0em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2);
  }
  62.5% {
    box-shadow:
      0em -2.6em 0em 0em rgba(0, 0, 0, 0.2),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2),
      2.5em 0em 0 0em rgba(0, 0, 0, 0.2),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.5),
      0em 2.5em 0 0em rgba(0, 0, 0, 0.7),
      -1.8em 1.8em 0 0em #ffffff,
      -2.6em 0em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2);
  }
  75% {
    box-shadow:
      0em -2.6em 0em 0em rgba(0, 0, 0, 0.2),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2),
      2.5em 0em 0 0em rgba(0, 0, 0, 0.2),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2),
      0em 2.5em 0 0em rgba(0, 0, 0, 0.5),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.7),
      -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2);
  }
  87.5% {
    box-shadow:
      0em -2.6em 0em 0em rgba(0, 0, 0, 0.2),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2),
      2.5em 0em 0 0em rgba(0, 0, 0, 0.2),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2),
      0em 2.5em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.5),
      -2.6em 0em 0 0em rgba(0, 0, 0, 0.7),
      -1.8em -1.8em 0 0em #ffffff;
  }
}

.slider-wrap {
  width: 100%;
  position: absolute;
  z-index: -2;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;

  .slider-img {
    position: absolute;
    max-width: 100%;
    max-height: 100vh;
    width: auto;
    height: auto;
    min-height: 100vh;
    @media all and (orientation: portrait) {
      height: 100%;
      max-width: none;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    animation-name: multiple-image-crossfade;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 15s;
    &:nth-of-type(1) {
      animation-delay: 12s;
    }
    &:nth-of-type(2) {
      animation-delay: 9s;
    }
    &:nth-of-type(3) {
      animation-delay: 6s;
    }
    &:nth-of-type(4) {
      animation-delay: 3s;
    }
    &:nth-of-type(5) {
      animation-delay: 0;
    }
  }
}

@keyframes multiple-image-crossfade {
  0% {
    opacity: 1;
  }
  17% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  92% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.srollToBottom {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 50%;

  position: fixed;
  bottom: 0;
  right: 0;
  width: 50px;
  height: 50px;
  background-color: #9d0b0f;
  z-index: 2;
  img {
    width: 40px;
    height: 40px;
    display: block;
    padding-top: 5px;
    padding-left: 5px;
  }
}

section.payment-page {
  display: block;
  width: 100vw;
  height: 100vh;
  background-color: $light;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  overflow-y: scroll;
  color: $text;
  visibility: hidden;
  opacity: 0;
  transition: all 0.35s ease-in;
  &.is-visible {
    visibility: visible;
    opacity: 1;
  }
  .outer-wrap {
    .container {
      position: relative;
      @media screen and (max-width: 576px) {
        padding: 0 15px;
      }
    }
  }
  .pp-header {
    padding: 30px 0;
    .logo {
      height: 40px;
      fill: $red;
    }
    .pp-close {
      width: 50px;
      height: 50px;
      display: flex;
      border-radius: 50%;
      border: 2px solid $text;
      position: absolute;
      right: 0;
      top: 0;
      margin: 30px 0;
      justify-content: center;
      align-items: center;
      transition: 0.3s;
      @media screen and (max-width: 576px) {
        margin-top: 15px;
        right: 15px;
      }
      svg {
        width: 20px;
        height: 20px;
        path {
          fill: $text;
        }
      }
      &:hover {
        background-color: white;
        svg path {
          fill: $black;
        }
      }
    }
  }
  .inner-wrap {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 576px) {
      flex-direction: column;
    }
    .left,
    .right {
      width: calc(50% - 30px);
      @media screen and (max-width: 576px) {
        width: 100%;
        margin-bottom: 50px;
      }
    }
    .left {
      .each {
        p,
        li {
          font-size: 14px;
          color: $text;
          line-height: 1.2;
          opacity: 1;
          a {
            display: inline;
            font-weight: bold;
            border-bottom: 1px dashed $red;
          }
        }
        + .each {
          margin-top: 30px;
        }
        li {
          list-style: none;
          display: block;
          position: relative;
          padding-left: 30px;
          margin-top: 8px;
          font-size: 16px;
          padding-top: 5px;
          &::before {
            content: '';
            display: block;
            width: 25px;
            height: 25px;
            background-image: url(../src/assets/imgs/tick.svg);
            background-size: 100%;
            background-repeat: no-repeat;
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      }
    }
  }
}

form {
  label.form-label {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    // color: $red;
  }
  .form-group {
    & {
      margin-top: 15px;
    }
  }
  .form-control {
    width: 100%;
    height: 50px;
    font-size: 18px;
    line-height: 18px;
    border: 1px solid $red;
    padding: 0 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: 0.3s;
    &:disabled {
      cursor: not-allowed;
      opacity: 0.7;
    }
    &::placeholder {
      opacity: 0.5;
    }
    &:focus::placeholder {
      opacity: 0;
    }
    &:not(:last-child) {
      box-shadow:
        0 0 8px $red,
        inset 0 0 8px $red;
      color: $red;
      &::placeholder {
        color: white;
      }
    }
  }

  button[type='submit'] {
    margin-top: 30px;
  }

  .form-control + [class*='error'] {
    margin-top: 10px;
    color: red;
    font-size: 13px;
  }
}
.subscriptionArea {
  position: relative;
  .loading-container {
    position: absolute;
    left: -5px;
    top: 0;
    width: calc(100% + 10px);
    height: 100%;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;
    text-align: center;
    font-size: 16px;
    z-index: 2;
  }
}

select {
  option {
    background-color: $black;
    color: white;
  }
}

.subscriptionArea {
  select {
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    background: url(../src/assets/imgs/arrow-down.svg) 0 0 no-repeat;
    background-size: 20px auto;
    background-position: calc(100% - 8px) center;
  }
}

input.me {
  width: 20px;
  height: 20px;
  border: 1px solid $red;
  border-radius: 2px;
  &:checked {
    background-color: $red;
    background-image: url(../src/assets/imgs/tick-icon.svg);
    background-repeat: no-repeat;
    background-size: 15px;
    background-position: center center;
    transform: scale(1.2);
  }
}

.errorMsg {
  max-width: none;
  font-size: 16px;
  margin: 15px 0;
  padding: 8px 8px 0;
  text-align: left;
  padding: 5px 8px 3px;
  color: red;
  background-color: #ff00004f;

  a {
    color: $red;
    text-decoration: underline;
    padding: 5px;
    display: none;
  }
}

.hidden {
  display: none;
}
